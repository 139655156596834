// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS for creating your own CSS components



/**
stylowanie dla edytora SQL w raportach
*/
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/idea.css';

.CodeMirror {
  border: 1px solid #ccc;
}

.CodeMirror-focused {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}


// end


.btn-ow {
  color: #fff;
  background-color: #ee2c36;
  border-color: #ee2c36;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name,
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email
{
  max-width: 12rem;
}
